import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import ClipboardCopy from '../../../components/worksafe-vic/ClipboardCopy';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#testing",
        "aria-label": "testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing`}</h2>
    <p>{`This page includes test data and other information to make sure your integration works as planned. It can be used to trigger different flows in your integration and ensure they are handled accordingly.`}</p>
    <p>{`For end to end testing with WorkSafe Victoria to submit to WorkSafe Victoria's testing environment claiming engine the latest test details are listed below:`}</p>
    <h4 {...{
      "id": "test-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#test-providers",
        "aria-label": "test providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test providers:`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Provider Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Provider Number`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`First name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`ABN`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Physio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2382464A `}<ClipboardCopy text="2382464A" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PHYSIO CHIRO SERVICE PROVIDER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59 336 445 411  `}<ClipboardCopy text="59 336 445 411" mdxType="ClipboardCopy" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Physio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0710446T `}<ClipboardCopy text="0710446T" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ABLITT PHYSIO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 144 889 636  `}<ClipboardCopy text="50 144 889 636" mdxType="ClipboardCopy" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0111827Y `}<ClipboardCopy text="0111827Y" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`John`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GENERAL PRACTIONER GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`99 128 211 843  `}<ClipboardCopy text="99 128 211 843" mdxType="ClipboardCopy" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0111829X `}<ClipboardCopy text="0111829X" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`John`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GENERAL PRACTIONER GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`99 128 211 843  `}<ClipboardCopy text="99 128 211 843" mdxType="ClipboardCopy" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PH0060   `}<ClipboardCopy text="PH0060" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`J`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PHARMACY SERVICE PROVIDER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78 414 009 530  `}<ClipboardCopy text="78 414 009 530" mdxType="ClipboardCopy" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PH0038   `}<ClipboardCopy text="PH0038" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PHARMACY SERVICE PROVIDER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43 083 841 007  `}<ClipboardCopy text="43 083 841 007" mdxType="ClipboardCopy" /></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "test-claims",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#test-claims",
        "aria-label": "test claims permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test claims:`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Worksafe Victoria test claim number`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`First name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of Injury`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Comments`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Standard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000492 `}<ClipboardCopy text="12210000492" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Standardclaim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1980-07-01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-05-30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can invoice for all types (GP, Physio, Pharmacy) Updated to reject depression, anxiety and headache migraine pharmacy categories`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Catastrophic`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000502 `}<ClipboardCopy text="12210000502" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catastropic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1990-01-01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-03-01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can invoice for all types (GP, Physio, Pharmacy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Provision Payments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000515 `}<ClipboardCopy text="12210000515" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JPPClaimohn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1989-08-01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-03-30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can invoice for all types (GP, Physio, Pharmacy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EMPLOYER Liability NOT ACCEPTED`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000528 `}<ClipboardCopy text="12210000528" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MANDLNO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1995-01-01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2021-02-01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoice will fail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Self Insured`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PP          `}<ClipboardCopy text="PP" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jensen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gibb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1949-09-13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exclusion period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000272 `}<ClipboardCopy text="12210000272" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exclusion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1983-06-13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-01-01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exclusion - services not valid within the below dates. If you bill either side of these dates then invoice will be approved/Pending Doctor between 01/10/21 - 5/11/21 Physio between 01/10/21 -05/11/2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gap in Treatment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1091024377  `}<ClipboardCopy text="1091024377" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anthony`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blazer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1958-06-10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1991-11-18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No claim for more than 3 years; this should go to WSV for review.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Note:If the above details do not work please contact our integration team `}<a parentName="p" {...{
          "href": "mailto:partnerships@medipass.io."
        }}>{`partnerships@medipass.io.`}</a>{` `}</p>
    </blockquote>
    <h5 {...{
      "id": "stubbing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#stubbing",
        "aria-label": "stubbing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stubbing`}</h5>
    <p>{`To activate the stub, patient membership number should be 77777777776`}<ClipboardCopy text="77777777776" mdxType="ClipboardCopy" /></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim total amount`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Simulated response`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0.63`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{` On item will simulate a 10 secs delay`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0.85`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`On item will simulate claim item rejection`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0.15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`On item will simulate an approved but under paid benefit item`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "general-test-cases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#general-test-cases",
        "aria-label": "general test cases permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General test cases`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Test No`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Test Procedure`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Expected results`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim for a provider consultation. Set a webhook for status updates.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The claim is transmitted successfully and a statement is printed correctly. The correct status is reflected.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim with an invalid WorkSafe Claim Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The interface displays an invalid item alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim where description for this item is required to be updated.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The interface displays description is required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Form cannot be submitted without Description if the Description flag is true.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim with a private item where without the prescribers Last name or number supplied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submission fails`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim and submit an invoice with an item where "Number of Patients" is required, without supplying a number of patients.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The interface displays the number of patients is required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a GP Consultation and submit  with all invoices lines accepted (MBS item)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a GP Consultation and submit with all invoices lines accepted (WSV item)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit a Private Pharmacy invoice with multiple lines and all invoices lines accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There are different required fields for Private scripts vs PBS or OTC. PBS and OTC only require Quantity, where are Private scripts must additionally have prescription info`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit a Pharmacy invoice with multiple lines. All invoices lines accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoice items will be marked as pending Review and Recommend Different Price`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit a Pharmacy invoice with multiple lines.  All invoices lines accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoice items will be marked as pending Review and Accepted)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a GP Consultation claim with valid claim number  and submit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is successfully submitted and approved`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a duplicate GP Consultation from the above test and submit it to be placed in Pending Review (Possible Duplicate Different Invoice)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is placed in Pending Review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit a GP Consultation that will be placed in Pending Review due to Exclusion Period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is placed in Pending Review due to Exclusion Period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit a Pharmacy claim that will be placed  in Pending Review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is placed in  Pending Review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a GP Consultation and submit with all invoices lines denied (Medical & Like not accepted on claim)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Interface displays invoices lines denied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create and submit an invoice where all invoices lines denied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Review marked as Denied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a claim that would have been placed into Pending Review is instead Denied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim is placed in pending review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancel a claim that is not in the settling status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancellation Accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancel a claim that is in the settling status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancellation not accepted - Invoice is in a state where it cannot be cancelled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      